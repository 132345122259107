<template>
    <div
        v-if="loading || items?.length"
        class="h-content w-100"
        style="overflow: visible;"
    >
        <v-card
            flat
            style="overflow: visible"
        >
            <v-card-title
                class="px-0 text-h5"
                v-text="seed.title"
            />
            <v-carousel
                hide-delimiters
                color="primary"
                :cycle="false"
                show-arrows
                style="width: 100% important;"
                class="d-block d-sm-none"
            >
                <template #prev="{ props: btnProps }">
                    <v-scroll-x-transition>
                        <v-btn
                            icon="chevron-left"
                            variant="flat"
                            color="#0000000f"
                            class="text-white rounded-circle"
                            @click="btnProps.onClick"
                        />
                    </v-scroll-x-transition>
                </template>
                <template #next="{ props: btnProps }">
                    <v-scroll-x-reverse-transition>
                        <v-btn
                            icon="chevron-right"
                            variant="flat"
                            color="#0000000f"
                            class="text-white rounded-circle"
                            @click="btnProps.onClick"
                        />
                    </v-scroll-x-reverse-transition>
                </template>
                <v-carousel-item
                    v-for="item, i in items"
                    :key="i"
                >
                    <lazy-housing-card
                        v-if="item"
                        style="width: 320px; height: 100%;"
                        class="mx-auto border rounded"
                        :housing="item"
                    />
                </v-carousel-item>
            </v-carousel>
            <v-slide-group
                class="pt-4 d-none d-sm-flex"
                :class="{ 'mx-md-n15': items.length > 4 }"
                :show-arrows="true"
                :disabled="true"
            >
                <v-slide-group-item
                    v-for="item, i in items"
                    :key="i"
                >
                    <lazy-housing-card
                        v-if="item"
                        style="width: 320px; height: 100%;"
                        class="mx-2 border rounded"
                        :housing="item"
                    />
                </v-slide-group-item>
            </v-slide-group>
            <v-card-actions class="justify-end">
                <v-btn
                    :to="seed.link"
                    text="View All"
                    color="primary"
                />
            </v-card-actions>
        </v-card>
    </div>
</template>

<script
    setup
    lang="ts"
>
import type { SearchResult } from '~/composables/useSearch'

const props = defineProps<{ seed: RecommendationSeed }>()

interface RecommendationSeed {
    title: string
    filters: Record<string, any>
    link: string
    descendingOrder?: boolean
}

const { data: displayedItems, pending } = await useFetch<SearchResult>('/api/search', {
    method: 'post' as const,
    body: { text: '', filters: props.seed.filters, page: 1 },
    key: props.seed.title,
})

const loading = computed(() => pending.value || !displayedItems.value?.results.length)

const items = computed(() => displayedItems.value?.results ?? [false, false, false, false])
if(props.seed.descendingOrder) {
    items.value.sort((b, a) => a.ListPrice - b.ListPrice)
}
</script>

<style scoped></style>
